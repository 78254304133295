<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">비밀번호 찾기</template>

            <div class="mw-400px mx-auto">
                <v-row class="row--sm">
                    <v-col cols="12">
                        <v-text-field v-model="username" v-bind="{ ...attrs_input, loading }" :dense="false" placeholder="아이디" />
                    </v-col>
                    <v-col>
                        <v-phone-field v-model="phone" v-bind="{ ...attrs_input, loading }" :dense="false" />
                    </v-col>
                    <v-col cols="auto">
                        <v-btn x-large color="grey" :disabled="!phone" @click="certify"> 인증번호전송 </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="otp" v-bind="{ ...attrs_input, loading }" :dense="false" type="number" placeholder="인증번호를 입력하세요." />
                    </v-col>
                </v-row>

                <div class="btn-wrap">
                    <v-row class="row--sm">
                        <v-col cols="12">
                            <v-btn block color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="recovery">찾기</v-btn>
                        </v-col>
                        <v-col cols="12">
                            <div class="divider-group text-center">
                                <ul class="grey--text">
                                    <li>
                                        <router-link to="/login">로그인</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/login/find-id/">아이디 찾기</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/join">회원가입</router-link>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { attrs_input } from "@/assets/variables";

import CryptoAES from "@/plugins/crypto-aes";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        VPhoneField,
    },
    data: () => {
        return {
            username: null,
            phone: null,
            otp: null,

            loading: false,
            attrs_input,
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function() {},

        async certify() {
            try {
                if (!this.username) throw new Error("아이디(이메일)을 입력해주세요");
                if (!this.phone) throw new Error("연락처를 입력해주세요");

                await this.postCertify();

                alert("인증번호가 전송되었습니다");
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        async recovery() {
            try {
                if (!this.username) throw new Error("아이디(이메일)을 입력해주세요");
                if (!this.phone) throw new Error("연락처를 입력해주세요");
                if (!this.otp) throw new Error("인증번호를 입력해주세요");

                var password = await this.postRecovery();

                alert(`임시비밀번호[${password}]로 변경되었습니다`);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        postCertify() {
            if (this.loading) return;
            else this.loading = true;
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/users/certify", {
                        type: "password",
                        username: this.username,
                        phone: this.phone.phoneNumberFormat(),
                    });

                    resolve();
                } catch (error) {
                    reject(error);
                } finally {
                    this.loading = false;
                }
            });
        },

        postRecovery() {
            if (this.loading) return;
            else this.loading = true;

            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/users/recovery", {
                        type: "password",
                        username: this.username,
                        phone: this.phone.phoneNumberFormat(),
                        otp: this.otp,
                    });

                    resolve(CryptoAES.decrypt(res.data.password));
                } catch (error) {
                    reject(error);
                } finally {
                    this.loading = false;
                }
            });
        },
    },
};
</script>
