<template>
    <a v-bind:id="'naver-login-button'" class="button button--social-n" v-bind:href="`https://nid.naver.com/oauth2.0/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}`">
        <slot default>네이버 계정으로 로그인</slot>
    </a>
</template>

<script>
export default{
    props: {
        client_id: { type: String, default: '' }
    },
    computed: {
        redirect_uri(){
            return `${window.location.protocol}//${window.location.hostname}/oauth/naver`;
        }
    }
};
</script>
